// Generated by Framer (c93245f)

import { addFonts, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["VDqnk6j9f"];

const serializationHash = "framer-BXPaW"

const variantClassNames = {VDqnk6j9f: "framer-v-110qvwr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "VDqnk6j9f", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://streamflow.finance/"}><Image {...restProps} as={"a"} background={{alt: "", fit: "fit", intrinsicHeight: 134, intrinsicWidth: 488, pixelHeight: 134, pixelWidth: 488, positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/OEXgMZ1ZrBJFqIxIOPl22Ngfms.png"}} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-110qvwr", className, classNames)} framer-1yfdv0x`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"VDqnk6j9f"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}/></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-BXPaW.framer-1yfdv0x, .framer-BXPaW .framer-1yfdv0x { display: block; }", ".framer-BXPaW.framer-110qvwr { height: 165px; position: relative; text-decoration: none; width: 402px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 165
 * @framerIntrinsicWidth 402
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramervFGl78kCT: React.ComponentType<Props> = withCSS(Component, css, "framer-BXPaW") as typeof Component;
export default FramervFGl78kCT;

FramervFGl78kCT.displayName = "streamflow";

FramervFGl78kCT.defaultProps = {height: 165, width: 402};

addFonts(FramervFGl78kCT, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})